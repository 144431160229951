<!-- 咨询列表 -->
<template>
  <div>
    <div class="container">
      <el-card>
        <div class="tile_box">
          <div class="title">行业资讯</div>
        </div>
        <div v-if="list.length > 0">
          <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index" @click="toDetail(item.noticeId)">
              <div class="time">{{ item.createTime.slice(0, 10) }}</div>
              <div class="title">
                {{ item.noticeTitle }}
              </div>
              <div class="noticeContent">
                {{ item.noticeTitle }}
              </div>
            </div>
          </div>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="handleCurrentChange"
            style="text-align: right; margin-top: 20px"
          >
          </el-pagination>
        </div>
        <el-empty :image-size="200" v-else></el-empty>
      </el-card>
    </div>
    <!-- 底部     -->
    <elFooter></elFooter>
  </div>
</template>

<script>
import { getAll } from '@/api/login'
import elFooter from '@/components/footer'

export default {
  components: { elFooter },

  data() {
    return {
      list: [],
      page: 1,
      pageSize: 12,
      total: 0,
    }
  },
  created() {
    this.getAll()
  },
  methods: {
    getAll() {
      getAll(this.page, this.pageSize).then((res) => {
        this.list = res.data.list
        this.total = res.data.total
      })
    },

    handleCurrentChange(val) {
      this.page = val
      this.getAll()
    },
    toDetail(id){
      this.$router.push('/consultDetail?id='+id)
    }
  },
}
</script>
<style lang='scss' scoped>
.container {
  .tile_box {
    margin-top: 20px;
    border-bottom: 2px solid #dddddd;
    position: relative;
    .title {
      position: absolute;
      top: -30px;
      font-size: 22px;
      font-weight: bold;
      border-bottom: 2px solid #fcb110;
      width: 95px;
      line-height: 30px;
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: 30%;
      background-color: #f5f5f5;
      cursor: pointer;
      transition: all 0.4s ease-in;
      padding: 20px;
      margin-top: 20px;
      .time {
        font-size: 20px;
        font-weight: 500;
        color: #dcdcdc;
        margin-bottom: 15px;
      }
      .title {
        font-size: 20px;
        font-weight: 500;
        color: #303133;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        word-break: break-all;
        margin-bottom: 10px;
      }
      .noticeContent {
        color: #909399;
        font-size: 16px;
      }
    }
    .item:hover {
      background: #202735;
      transition: all 0.5s ease-in;
      transform: scale(1.03);
      .title{
        color: #fff;
      }
    }
  }
}
</style>