<template>
  <div class="footer" :style="isLogining ? 'background-image: linear-gradient(to right, #3c61be , #356ddf);border-top:1px solid #fff;' : 'footer'">
    <div class="footer-nav">
      <template v-for="(item, index) in menus">
        <div
          class="item"
          :style="isLogining ? 'color:#fff;' : ''"
          :key="index"
          v-if="!item.hidden"
          @click="handleTo(item.path)"
        >
          {{ item.name }}
        </div>
      </template>
    </div>
    <div class="container">
      <div class="power">
          <div class="left" :style="isLogining ? 'color:#fff;' : ''">
              <div class="top">
                <span>Copyright © 2008-2021</span>
                <span style="margin: 0 20px;">版权所有运营商：深圳蔚蓝思科科技有限公司</span>
                <span>桂ICP备000000号-5</span>
              </div>
              <div class="bottom">
                <span>增值电信业务经营许可证：桂B2-20210101</span>
                <span>公网安备000000000号</span>
              </div>
            </div>
        <!-- <el-row :gutter="30">
          <el-col :span="13">
          
          </el-col>
          <el-col :span="11">
            <div class="right">
              <div class="icon" v-for="(n, index) in 6" :key="index">
                <img
                  :src="require(`@/assets/images/footer-icon-${n}.png`)"
                  alt=""
                />
              </div>
            </div>
          </el-col>
        </el-row> -->
      </div>
    </div>
  </div>
</template>

<script>
import { menus } from '@/utils/common'

export default {
  data() {
    return {
      menus,
    }
  },
  computed: {
    activeIndex() {
      return this.$store.state.setting.activeIndex
    },
    isLogining() {
      if (this.activeIndex === '/login' || this.activeIndex === '/register') {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    handleTo(path) {
      this.$router.push(path)
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/common.styl'
.footer
  width 100%
  background $footer-bg
  .footer-nav
    background transparent !important
    display flex
    justify-content center
    align-items center
    height 50px
    .item
      color #7688Ae
      font-size 14px
      padding 0 20px
      cursor pointer
      transition all 0.5s
      &:hover
        color #FF5800
  .power
    padding 20px 0
    display flex
    align-items center
    justify-content center
    .left
      font-size 12px
      color #7688Ae
      line-height 30px
      .top
        display flex
        justify-content space-between
      .bottom
        text-align center
        span
          padding-right 30px
</style>
